import { format, setDate } from 'date-fns';
import { isEmpty } from 'ramda';
import type { IDatePicker, IDatePickerUI, WeekDay, WeekDayUI } from './types';

interface IDayUI {
  [k: string]: WeekDayUI;
}

interface IDay {
  [k: string]: IDatePicker['d'];
}

/**
 *
 * Map UI datePicker weekDay to short date db property `d`
 * @param {WeekDayUI} type
 * @returns {IDatePicker['d']}
 */
const mapWeekDayFromUI = (type: WeekDayUI): IDatePicker['d'] => {
  const day: IDay = {
    default: 'sat',
    Friday: 'fri',
    'Mon-Thurs': ['mon', 'tue', 'wed', 'thu'],
    Sunday: 'sun',
  };
  return day[type] || day.default;
};

/**
 *
 * Map UI datePicker day / weekDay to date db property `d`
 * @param {IDatePickerUI} dayProps
 * @returns {IDatePicker['d']}
 */
const mapDayFromUI = (dayProps: IDatePickerUI): IDatePicker['d'] => {
  const { weekDay, day } = dayProps;
  if (day) return Number(day);
  if (weekDay) return mapWeekDayFromUI(weekDay);
  return null;
};

/**
 *
 * Map UI datePicker month / season to date db property `m`
 * @param {IDatePickerUI} monthProps
 * @returns {IDatePicker['m']}
 */
const mapMonthFromUI = (monthProps: IDatePickerUI): IDatePicker['m'] => {
  const { month, season } = monthProps;
  if (month) return Number(month);
  if (season) return season;
  return null;
};

/**
 *
 * Map UI datePicker year to date db property `y`
 * @param {IDatePickerUI} yearProps
 * @returns {IDatePicker['y']}
 */
const mapYearFromUI = (yearProps: IDatePickerUI): IDatePicker['y'] => {
  const { year } = yearProps;
  if (year) return Number(year);
  return null;
};

/**
 *
 * Map date db property `d` to datePicker UI weekDay
 * @returns {(WeekDayUI)}
 */
const mapDayToUI = (type: WeekDay | WeekDay[]): WeekDayUI => {
  const day: IDayUI = {
    default: 'Mon-Thurs',
    fri: 'Friday',
    sat: 'Saturday',
    sun: 'Sunday',
  };
  if (Array.isArray(type)) return day.default;
  return day[type] || day.default;
};

/**
 *
 * Map UI datePicker to db date props
 * @param {IDatePickerUI} datePicker
 * @returns {IDatePicker}
 */
export const mapDateFromUI = (datePicker: IDatePickerUI): IDatePicker => ({
  d: mapDayFromUI(datePicker),
  m: mapMonthFromUI(datePicker),
  y: mapYearFromUI(datePicker),
});

export const mapDateToIsoFormat = (datePicker: IDatePicker): string =>
  format(
    setDate(new Date(Number(datePicker.y), Number(datePicker.m), 1), Number(datePicker.d)),
    'yyyy-MM-dd',
  );

/**
 *
 * Map db date props to UI datePicker
 * @param {IDatePicker} date
 * @returns {IDatePickerUI}
 */
export const mapDateToUI = (date?: IDatePicker): IDatePickerUI | null => {
  if (!date || isEmpty(date)) return null;
  const { d, m, y } = date;

  return {
    day: typeof d === 'number' ? String(d) : '',
    dayUndecided: d === null,
    exactDate: '',
    month: typeof m === 'number' ? String(m) : '',
    monthUndecided: m === null,
    season: typeof m === 'string' ? m : '',
    weekDay: typeof d === 'string' || Array.isArray(d) ? mapDayToUI(d) : '',
    year: typeof y === 'number' ? String(y) : '',
    yearUndecided: y === null,
  };
};

/**
 *
 * Typeguard for IDatePickerUI type
 * @param {any} date
 * @returns {boolean}
 */
export const isUIDate = (date: any): date is IDatePickerUI =>
  typeof date.day === 'string' && typeof date.month === 'string' && typeof date.year === 'string';
