import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { CohortNames, IAbTestingState } from 'src/ab-testing/lib/types';

export interface AbTestingState {
  abTesting: IAbTestingState;
}

/**
 * Select the abTests slice from the state.
 */
const getAbTests = (state: AbTestingState): Record<string, CohortNames> => state.abTesting.abTests;

/**
 * Select a test from the state.
 */
export const getTest = createCachedSelector(
  getAbTests,
  (_: AbTestingState, id: string) => id,
  (abTests, id): CohortNames => abTests?.[id],
)((_, id) => id);

/**
 * Select the abTestsLoaded flag from the state.
 */
const getAbTestsLoaded = (state: AbTestingState): boolean => state.abTesting.abTestsLoaded;

/**
 * Returns test variant if activated or falls back to 'control'
 */
export const getTestVariant = createCachedSelector(
  getAbTests,
  (_: unknown, id: string) => id,
  (abTests, id): CohortNames => abTests?.[id] || 'control',
)((_, id) => id);

/**
 * Returns true if the test has a variant in store
 */
export const getIsTestActivated = createCachedSelector(
  getAbTests,
  (_: AbTestingState, id: string) => id,
  (abTests, id): boolean => !!abTests && Object.keys(abTests).includes(id),
)((_, id) => id);

/**
 * Returns whether the AB tests are loaded
 */
export const getAreTestsLoaded = createSelector(
  getAbTestsLoaded,
  (abTestsLoaded): boolean => abTestsLoaded,
);

export const getLoaderVisible = (state: AbTestingState) => state.abTesting.loaderVisible;
