import Cookies from 'universal-cookie';
import { env } from '../env';

export const E2E_TESTING_COOKIE = 'E2E_TESTS';

/**
 * This function checks a cookie to see if the user is running E2E tests. It is
 * used to add conditional logic in the frontends, for example to have a
 * different authentication approach or to disable AB tests / hide some modals.
 */
export const getIsE2E = () => {
  if (typeof window === 'undefined' || env.LIVE) return false;

  const cookies = new Cookies();

  return cookies.get('E2E_TESTS') === 'true';
};
