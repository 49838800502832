import { head, pathOr, values } from 'ramda';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { Slug } from '@bridebook/toolbox/src/types';
import { getShortlistTabFromQuery } from 'lib/app/selectors';
import { getEnquiryDates } from 'lib/enquiries/selectors';
import { env } from 'lib/env';
import {
  bookedSupplierList,
  getActiveShortlistCategories,
  getSupplierIdFromShortlistUrlId,
} from 'lib/shortlist/utils';
import {
  getCurrentSupplierCategoriesForWeddingType,
  getIsSupplierVenue,
} from 'lib/supplier/selectors';
import { IApplicationState, SupplierCategory, TUIShortlistSupplier } from 'lib/types';
import { filterByType } from 'lib/utils';
import { createDeepEqualSelector } from 'lib/utils/selectors';
import getLastBookedSupplier from 'lib/weddings/utils/get-last-booked-supplier';
import getLastBookedVenue from 'lib/weddings/utils/get-last-booked-venue';
import { filterSupplierListByContacted } from './utils';

const getShortlist = (state: IApplicationState) => state.shortlist;
const _getPathname = (state: IApplicationState) => state.app.pathname;

const _getShortlistList = createSelector(getShortlist, ({ list }) => list);
const _getShortlistLoaded = createSelector(getShortlist, ({ loaded }) => loaded);
const _openedSupplierId = createSelector(getShortlist, ({ openedSupplierId }) => openedSupplierId);

export const getFromBookedSuppliers = createSelector(
  getShortlist,
  ({ fromBookedSuppliers }) => fromBookedSuppliers,
);
export const getCustomSupplier = createSelector(
  getShortlist,
  ({ customSupplier }) => customSupplier,
);

export const getSupplierToBook = createSelector(
  getShortlist,
  ({ confirmBookingModal }) => confirmBookingModal?.supplierToBook,
);

export const getAddSupplierModalOpened = createSelector(
  getShortlist,
  ({ addSupplierModalOpened }) => addSupplierModalOpened,
);

/* ############################################################################
 *  MEMOIZED SELECTORS
 * ######################################################################### */

export const getBookedVenue = createSelector([_getShortlistList], (shortlist) =>
  values(shortlist).find((item) => item.type === 'venue' && item.booked),
);

export const getShortlistCount = createSelector([_getShortlistList], (list) => values(list).length);

export const getWeddingVenue = createSelector([_getShortlistList], (shortlist) =>
  getLastBookedVenue(shortlist),
);

export const getShortlistIds = createSelector([_getShortlistList], (list) => Object.keys(list));

export const getShortlistList = createSelector([_getShortlistList], (shortlist) =>
  Object.values(shortlist),
);

export const getShortlistedVenuesList = createSelector([_getShortlistList], (shortlist) =>
  values(shortlist)
    .filter((item) => (item.type || item.type) === 'venue')
    .reverse(),
);

export const getBookedVenuesList = createSelector([getShortlistedVenuesList], (shortlistedVenues) =>
  shortlistedVenues.filter((item) => item.booked === true),
);

export const getIsVenuerexSuggested = createSelector(
  [getShortlistedVenuesList, getBookedVenuesList],
  (shortlistedVenuesList, bookedVenuesList) => {
    const anyShortlisted = shortlistedVenuesList.length > 0;
    const anyBooked = bookedVenuesList.length > 0;
    return anyShortlisted && !anyBooked;
  },
);

export const getIsVenuerexVisible = createSelector(
  [
    (state: IApplicationState) => getIsVenuerexSuggested(state),
    (state: IApplicationState) => getIsSupplierVenue(state),
  ],
  (isVenuerexSuggested, isSupplierVenue) => isVenuerexSuggested || isSupplierVenue,
);

export const isSupplierShortlisted = createCachedSelector(
  _getShortlistList,
  (_: IApplicationState, supplierId: string | undefined) => supplierId,
  (shortlist, supplierId) => !!supplierId && shortlist && Boolean(shortlist[supplierId]),
)((_, supplierId) => supplierId || 'undefined'); // Cache selector by supplier id

export const getShortlistedSupplierDetails = createCachedSelector(
  _getShortlistList,
  (_: IApplicationState, supplierId: string | undefined) => supplierId,
  (shortlist, supplierId) => (supplierId ? shortlist[supplierId] : null),
)((_, supplierId) => supplierId || 'undefined'); // Cache selector by supplier id

const _getShortlistCategories = createSelector(
  [
    (state: IApplicationState) => _getShortlistList(state),
    (state: IApplicationState) => getShortlistTabFromQuery(state),
    (state: IApplicationState) => getCurrentSupplierCategoriesForWeddingType(state),
  ],
  (shortlist, tabOpened, supplierCategories): SupplierCategory[] => {
    const isBooked = tabOpened === 'booked';
    return isBooked
      ? getActiveShortlistCategories(shortlist, supplierCategories)
      : supplierCategories;
  },
);

export const getShortlistCategories = createDeepEqualSelector(
  _getShortlistCategories,
  (categories) => categories,
);

export interface IShortlistCategoryInfo {
  isBooked: boolean;
  isShortlisted: boolean;
  shortlistedCount: number;
  bookedCount: number;
  latestBookedItem?: TUIShortlistSupplier;
}

export const getShortlistCategoryInfo = createCachedSelector(
  _getShortlistList,
  (_: IApplicationState, slug: Slug) => slug,
  (list, slug) => {
    const categoryItems = filterByType(values(list), slug);
    const bookedItems = bookedSupplierList(categoryItems);
    const isBooked = bookedItems.length > 0;
    const isShortlisted = categoryItems.length > 0 && !isBooked;
    const latestBookedItem = head(bookedItems);

    return {
      isBooked,
      isShortlisted,
      shortlistedCount: categoryItems.length,
      bookedCount: bookedItems.length,
      latestBookedItem,
    };
  },
)((_, slug) => slug); // Cache selectors by category slug

export const getNonCustomSuppliersToContactList = createSelector(
  [
    (state: IApplicationState) => getShortlistList(state),
    (state: IApplicationState) => getEnquiryDates(state),
  ],
  (shortlisted, enquiryDates) => {
    const suppliersToContact = filterSupplierListByContacted({
      supplierList: shortlisted,
      contactedList: enquiryDates,
    });

    return suppliersToContact.filter((item) => !item.customSupplier);
  },
);

export const getOpenedSupplierDetails = createSelector(
  [_openedSupplierId, _getShortlistList],
  (supplierId, list) =>
    supplierId
      ? values(list).find(
          (s) =>
            getSupplierIdFromShortlistUrlId(supplierId)?.toLowerCase() ===
            (s.publicId?.toLowerCase() || s.id.toLowerCase()),
        )
      : undefined,
);

export const getOpenedSupplierId = createSelector([_openedSupplierId], (supplierId) => supplierId);

export const getShortlistedNonVenue = createSelector([_getShortlistList], (shortlist) =>
  values(shortlist).filter((s) => s.slug !== 'venue'),
);

export const getBookedNonVenue = createSelector([_getShortlistList], (shortlist) =>
  values(shortlist)
    .filter((s) => s.booked)
    .filter((s) => (s.slug || s.type) !== 'venue'),
);

export const getBookedSuppliers = createSelector([_getShortlistList], (shortlist) =>
  values(shortlist).filter((s) => s.booked),
);

export const getIsShortlistPage = createSelector([_getPathname], (pathname) =>
  pathname.startsWith('/shortlist'),
);

export const getIsCollaboratingPossible = createSelector(
  [getOpenedSupplierDetails],
  (supplier) =>
    // TODO: Remove check for UK only after we add translations to supplier-couple portals
    supplier &&
    supplier.countryCode === CountryCodes.GB &&
    (supplier.slug || supplier.type) === 'venue' &&
    !supplier.customSupplier,
);

export const getShortlistLoaded = createSelector(
  _getShortlistLoaded,
  (shortlistLoaded) => shortlistLoaded,
);

type TBookedCarouselSummary = Record<Slug, TUIShortlistSupplier[]>;

export const getShortlistSummary = createSelector(
  [_getShortlistList, getShortlistLoaded],
  (shortlist, loaded) => {
    if (!loaded) return null;

    const summary = Object.values(shortlist).reduce<TBookedCarouselSummary>(
      (acc, shortlistedSupplier) => {
        const { type } = shortlistedSupplier;
        if (!type) return acc;
        acc[type] = acc[type] || [];
        acc[type].push(shortlistedSupplier);
        return acc;
      },
      {} as TBookedCarouselSummary,
    );

    Object.keys(summary).forEach((key) => {
      summary[key as keyof TBookedCarouselSummary].sort(
        (a, b) => (b.updatedAt || 0) - (a.updatedAt || 0),
      );
    });

    return summary;
  },
);

// select venue booked - throws error if as part of wedding selectors
const selectVenueBooked = (state: IApplicationState) =>
  pathOr(false, ['venue'], state.weddings.profile.bookings);

export const getBookedSupplierCategories = createSelector(
  [_getShortlistList, _getShortlistLoaded, selectVenueBooked],
  (shortlist, shortlistLoaded, venueBooked) => {
    if (!shortlistLoaded) return null;
    let bookedSupplierCategories = Object.values(shortlist)
      .filter((shortlistItem) => shortlistItem.booked)
      .map((shortlistItem) => shortlistItem.type);

    // fix Homepage load on mobile fails with 404 when custom venue booked https://bridebook.atlassian.net/browse/LIVE-15925
    if (venueBooked && !bookedSupplierCategories.includes('venue')) {
      bookedSupplierCategories = ['venue', ...bookedSupplierCategories];
    }

    return bookedSupplierCategories;
  },
);

export const selectLastBookedPhotographer = createSelector([_getShortlistList], (shortlist) =>
  getLastBookedSupplier(shortlist, 'photo'),
);

export const getBookedVenuePublicId = createSelector([_getShortlistList], (shortlist) => {
  const bookedVenues = Object.values(shortlist).filter(
    (s) => (s.slug || s.type) === 'venue' && s.booked,
  );
  if (bookedVenues.length > 0) {
    return bookedVenues[0].publicId;
  }
  return null;
});

export const getIsShortlistedSuppliersLimitReached = createSelector(
  _getShortlistList,
  (list) => Object.keys(list).length >= env.SHORTLIST_USER_LIMIT,
);

export const getAddToBooked = createSelector(getShortlist, ({ addToBooked }) => addToBooked);

export const getAddSupplierModalTriggerLocation = createSelector(
  getShortlist,
  (shortlist) => shortlist.addSupplierModal.triggerLocation,
);

export const getIsSearchGoogleModalShown = createSelector(
  getShortlist,
  ({ showSearchGoogleModal }) => showSearchGoogleModal,
);

export const getIsCustomSupplierFormModalShown = createSelector(
  getShortlist,
  ({ showCustomSupplierFormModal }) => showCustomSupplierFormModal,
);

export const getSupplierReviewsStars = createSelector(
  getOpenedSupplierDetails,
  (supplierDetails) => supplierDetails?.reviewsStars,
);

export const getSupplierReviewsCount = createSelector(
  getOpenedSupplierDetails,
  (supplierDetails) => supplierDetails?.reviewsCount,
);

export const getSupplierPhotos = createSelector(
  getOpenedSupplierDetails,
  (supplierDetails) => supplierDetails?.photos,
);

export const getSupplierThumbnail = createSelector(
  getOpenedSupplierDetails,
  (supplierDetails) => supplierDetails?.thumbnail,
);

export const getCollaborationLoaded = createSelector(
  getShortlist,
  ({ collaborationLoaded }) => collaborationLoaded,
);

export const getShortlistedSupplierShortlistTime = createCachedSelector(
  _getShortlistList,
  (_: IApplicationState, supplierId: string | undefined) => supplierId,
  (shortlist, supplierId) => (supplierId && shortlist ? shortlist[supplierId]?.createdAt : null),
)((_, supplierId) => supplierId || 'undefined'); // Cache selector by supplier id
