import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAbTestVariant } from '@bridebook/toolbox/src/ab-testing';
import Experiments from '@bridebook/toolbox/src/ab-testing/ab-testing';
import { participatedInAbTestingAnalytics } from '@bridebook/toolbox/src/ab-testing/lib/actions/actions';
import { useAbTestsLoggedIn } from '@bridebook/toolbox/src/ab-testing/lib/hooks/use-ab-tests-logged-in';
import { CohortNames, Experiment } from '@bridebook/toolbox/src/ab-testing/lib/types';
import { getIsE2E } from '@bridebook/toolbox/src/e2e/get-is-e2e';
import { AbTestingState, getIsTestActivated } from '../selectors';

const SALT = '3b3652a7ca980aa1f7c9a31b44b230f7';

interface IProps {
  triggerCondition: boolean;
  userId: string | undefined;
  testId: string;
}

/**
 * Custom React hook to activate an A/B test and manage variant assignment for a user.
 *
 * This hook determines whether an A/B test should be activated based on various conditions.
 * If activation criteria are met, it assigns the user to a variant (cohort), dispatches actions
 * to update the Redux store, and sends analytics data.
 *
 * @param {Object} params - The parameters object.
 * @param {boolean} params.triggerCondition - Additional condition that must be true to activate the test.
 * @param {string | undefined} params.userId - The unique identifier of the user.
 * @param {string} params.testId - The unique identifier of the A/B test.
 *
 * @returns {CohortNames | undefined} - The variant (cohort) assigned to the user, or undefined if not assigned.
 *
 * @example
 * const variant = useActivateAbTest({
 *   isTestActivated,
 *   triggerCondition: true,
 *   userId,
 *   testId: 'experiment-123',
 *   dispatch,
 * });
 *
 * if (variant === 'control') {
 *   // Render control variant components
 * } else if (variant === 'variant') {
 *   // Render test variant components
 * }
 */
export const useActivateAbTest = ({
  triggerCondition,
  userId,
  testId,
}: IProps): CohortNames | undefined => {
  const dispatch = useDispatch();
  const assignedVariant = useRef<CohortNames | undefined>();
  const { abTests } = useAbTestsLoggedIn();
  const abTest = abTests?.find((test: Experiment) => test.id === testId);
  const isTestActivated = useSelector((state: AbTestingState) => getIsTestActivated(state, testId));

  useEffect(() => {
    if (!abTest || getIsE2E() || isTestActivated || !triggerCondition || !userId) return;

    const experiments = new Experiments(
      {
        version: '1.0',
        experiments: [abTest],
        salt: SALT,
      },
      userId,
      { id: userId },
    );
    assignedVariant.current = experiments.getCohort(testId) as CohortNames;

    dispatch(setAbTestVariant({ id: testId, variant: assignedVariant.current }));
    dispatch(participatedInAbTestingAnalytics({ abTest, variant: assignedVariant.current }));
  }, [abTest, dispatch, isTestActivated, testId, triggerCondition, userId]);

  return assignedVariant.current;
};
