import { CohortNames, IAbTestingState } from 'src/ab-testing/lib/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: IAbTestingState = {
  abTests: {},
  abTestsLoaded: false,
  loaderVisible: false,
};

const abTestingSlice = createSlice({
  name: 'abTesting',
  initialState,
  reducers: {
    setAbTests(state, action: PayloadAction<Record<string, CohortNames>>) {
      state.abTests = action.payload;
      state.abTestsLoaded = true;
    },
    setAbTestVariant(state, action: PayloadAction<{ id: string; variant: CohortNames }>) {
      const { id, variant } = action.payload;
      state.abTests[id] = variant;
    },
    setAbTestsLoaded(state, action: PayloadAction<boolean>) {
      state.abTestsLoaded = action.payload;
    },
    showFullpageLoader(state, { payload }: PayloadAction<boolean>) {
      state.loaderVisible = payload;
    },
  },
});

export const { setAbTests, setAbTestVariant, showFullpageLoader, setAbTestsLoaded } =
  abTestingSlice.actions;

export default abTestingSlice.reducer;
