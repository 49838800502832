import type { IElasticSupplier, IWeddingSupplier } from '@bridebook/toolbox/src/types';
import { authenticatedPOST } from '../api/auth/authenticated-fetch';
import { fetchSuppliersElastic } from '../supplier/fetch-suppliers-elastic';

/**
 * TS Hell
 * Since we are having soo many Supplier types, and also using
 * this "enrich" function with plain `{id: string}` objects,
 * we need some TS foo to properly map types coming in and out.
 *
 * @link https://bridebook.slack.com/archives/CEYNSDCFM/p1700767471240059
 *
 * In the end `enrichSuppliers` function should usually be called
 * with `IWeddingSupplier` type array. The purpose of this is to
 * "enrich" the `IElasticSupplier` which comes from ES with the
 * data from the `IWeddingSupplier` which comes from Firebase.
 *
 * The actual endpoint `/api/supplier/fetch-shortlist` should better be called
 * `/api/supplier/fetch-elastic-suppliers` or something like that.
 */

type IEnrichedSupplierIncoming<T> = T & Pick<IWeddingSupplier, 'id'> & Partial<IWeddingSupplier>;
export type IEnrichedSupplierOutgoing = IElasticSupplier &
  Partial<Pick<IWeddingSupplier, 'booked' | 'notes' | 'price' | 'conversation' | 'createdAt'>>;

/**
 * Uses the incoming supplier object to enrich the ES supplier object
 * with additional properties.
 */
const mapElasticSupplierToWeddingSupplier = <T = IWeddingSupplier>(
  eSupplier: IElasticSupplier,
  supplierIds: IEnrichedSupplierIncoming<T>[],
) => {
  const supplier = supplierIds.find((s) => eSupplier.id === s.id);
  //TODO: check if we can spread the 'supplier' below as any additional params added in the future would be missed
  // (added "enquired" property to fix: https://bridebook.atlassian.net/browse/LIVE-19567)

  if (supplier) {
    return {
      ...eSupplier,
      booked: supplier.booked,
      notes: supplier.notes,
      price: supplier.price,
      enquired: supplier.enquired,
      conversation: supplier.conversation,
      createdAt: supplier.createdAt,
    };
  }

  return eSupplier;
};

/**
 * Enriches the suppliers with data from Elastic.
 *
 * @param suppliers Best case as IWeddingSupplier[] object, worst case {id: string}[]
 * @returns IEnrichedSupplierOutgoing[]
 */
export const enrichSuppliers = async <I = IWeddingSupplier>(
  suppliers: IEnrichedSupplierIncoming<I>[],
): Promise<IEnrichedSupplierOutgoing[]> => {
  const supplierIds = Array.from(new Set(suppliers.map((s) => s.id)));

  const result = await authenticatedPOST<any, ReturnType<typeof fetchSuppliersElastic>>(
    `/api/supplier/fetch-suppliers`,
    {
      body: supplierIds,
    },
  );

  return result.map<IEnrichedSupplierOutgoing>((eSupplier) =>
    mapElasticSupplierToWeddingSupplier(eSupplier, suppliers),
  );
};
