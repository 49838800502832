import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAbTestsLoaded } from '@bridebook/toolbox/src/ab-testing';
import { useAbTestsFetch } from './use-ab-tests-fetch';

/**
 * Custom React hook that fetches all A/B test configurations and updates the Redux store when the data is loaded.
 *
 * This hook combines the data fetching logic from `useAbTestsFetch` with a side effect to update the Redux store
 * about the loading state of A/B tests. It is typically used in scenarios where A/B tests need to be initialized
 * for logged-in users.
 *
 * @returns An object containing:
 * - `abTests`: `Experiment[] | undefined` - The array of fetched A/B test configurations, or `undefined` if not yet loaded.
 * - `abTestsLoaded`: `boolean` - A flag indicating whether the A/B tests have been fetched and are loaded.
 *
 * @example
 * // Usage within a component
 * const { abTests, abTestsLoaded } = useAbTestsLoggedIn();
 *
 * @remarks
 * - **Redux Integration:** Dispatches `setAbTestsLoaded(true)` to the Redux store when the A/B tests have been loaded.
 * - **Dependency on `useAbTestsFetch`:** This hook relies on `useAbTestsFetch` to perform the actual data fetching.
 * - **Side Effects:** Uses `useEffect` to ensure that the Redux store is updated when the `abTestsLoaded` state changes.
 * - **Avoiding Infinite Loops:** The effect only dispatches when `abTestsLoaded` transitions to `true`, preventing unnecessary re-renders.
 */
export const useAbTestsLoggedIn = () => {
  const dispatch = useDispatch();

  const { abTests, abTestsLoaded } = useAbTestsFetch();

  useEffect(() => {
    if (!abTestsLoaded) {
      dispatch(setAbTestsLoaded(true));
    }
  }, [dispatch, abTestsLoaded]);

  return {
    abTests,
    abTestsLoaded,
  };
};
