import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import gazetteer, { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { mapSlugToFriendlyUrlSlug } from '../mapSlugToFriendlyUrlSlug';
import { IUISupplier } from '../types';

/**
 *
 * @param supplierCountryCode
 * @returns localisation string for supplier profile url
 */
export const getUrlCoupleSideLocale = (supplierCountryCode?: CountryCodes) =>
  gazetteer.getMarketByCountry(supplierCountryCode ?? CountryCodes.GB).prefix;

/**
 * Returns URL to the supplier profile, without base path.
 * Works with both Firestore and Elastic suppliers.
 *
 * E.g. /wedding-venues/jg-ven-maghera-cheshire-csBXdLAPX6
 */
export const getSupplierUrl = <T extends PartialFirestoreSupplier | PartialElasticSupplier>(
  supplier: T | null,
) => {
  if (!supplier) return '';
  const { publicId } = supplier;

  const { supplierType, friendlyUrlSlug } = (() => {
    if (isFirestoreSupplier(supplier)) {
      return {
        supplierType: supplier.type[0],
        friendlyUrlSlug: supplier.seo?.urlSlug,
      };
    } else {
      return {
        supplierType: supplier.type || supplier.slug,
        friendlyUrlSlug: supplier.seoUrlSlug,
      };
    }
  })();

  const categorySlug = mapSlugToFriendlyUrlSlug(supplierType).newSlug;

  return `/wedding-${categorySlug}/${friendlyUrlSlug}-${publicId}`;
};

/* ############################################################################
 *  HELPERS
 * ######################################################################### */

// We need partial types to be able to pass only partial objects, e.g. from local storage
type PartialFirestoreSupplier = Pick<ISupplier, 'name' | 'type' | 'publicId' | 'seo'> & {
  address: Pick<ISupplier['address'], 'adminArea' | 'city'>;
};

export type PartialElasticSupplier = Pick<
  IUISupplier,
  'name' | 'type' | 'publicId' | 'town' | 'county' | 'slug' | 'seoUrlSlug'
>;

// Helper for TS to determine type
const isFirestoreSupplier = (s: any): s is PartialFirestoreSupplier =>
  Array.isArray((s as PartialFirestoreSupplier).type);
