import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import { env } from 'lib/env';

export function assertExists<T>(val: T): asserts val is NonNullable<T> {
  if (val === undefined || val === null) {
    try {
      throw new Error('The value should not be null or undefined');
    } catch (e) {
      if (env.LIVE) {
        SentryMinimal().captureException(e);
      } else {
        throw e;
      }
    }
  }
}
