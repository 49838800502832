import Cookies from 'universal-cookie';
import { v4 as uuidV4 } from 'uuid';

/**
 * Set a cookie with the specified name and value. Default expiration is 1 year.
 */
const setCookie = (
  name: string,
  value: string | undefined,
  amount: number = 1,
  unit: 'days' | 'months' | 'years' = 'years',
) => {
  const currentDate = new Date();

  switch (unit) {
    case 'months':
      // Set the month of the date object to the current month plus the specified number of months
      currentDate.setMonth(currentDate.getMonth() + amount);
      break;
    case 'days':
      // Calculate the number of milliseconds in the specified number of days and set the time of
      // the date object to the current time plus the specified number of days
      currentDate.setTime(currentDate.getTime() + amount * 1000 * 60 * 60 * 24);
      break;
    case 'years':
    default:
      // Set the year of the date object to the current year plus the specified number of years
      currentDate.setFullYear(currentDate.getFullYear() + amount);
      break;
  }

  const cookies = new Cookies();
  cookies.set(name, value, {
    expires: currentDate,
    httpOnly: false,
    path: '/',
  });
};

/**
 * Utility object for managing a basic user ID stored in cookies.
 *
 * This object provides methods to get and set a basic user ID cookie. It's typically used to
 * generate and maintain a unique identifier for users who are not logged in, enabling features
 * like A/B testing for anonymous users.
 *
 * @example
 * // Retrieve the basic user ID from the cookie
 * const userId = basicUserIdCookie.get('basic_user_id');
 *
 * // Set the basic user ID cookie if it doesn't exist and get the user ID
 * const userId = basicUserIdCookie.set('basic_user_id');
 */
export const basicUserIdCookie = {
  get(cookieName: string) {
    const cookies = new Cookies();
    return cookies.get(cookieName);
  },

  set(cookieName: string) {
    const cookies = new Cookies();
    const cookieUserId = cookies.get(cookieName);
    const userId = cookieUserId || uuidV4();

    if (!cookieUserId) {
      setCookie(cookieName, userId);
    }

    return userId;
  },
};
