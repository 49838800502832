import gazetteer, { CountryCodes, Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import { getAppAnalyticsLocale, getCountryCode, getCurrencyCode } from 'lib/i18n/selectors';
import { isSearchLandingPage } from 'lib/search/utils';
import type { ShortlistedLocation, TShortlistTab } from 'lib/shortlist/types';
import type { IApplicationState } from 'lib/types';
import modalPropertiesGeneric from './modalPropertiesGeneric';
import testingPropertiesGeneric from './testingPropertiesGeneric';
import variationTest from './variationTest';

export { modalPropertiesGeneric, testingPropertiesGeneric, variationTest };

export interface ILocalisationProps {
  countryCode?: CountryCodes;
  countryName?: string;
  currency?: string;
  liteVersion?: boolean;
  locale?: string;
}

/**
 * To avoid having incorrect data in analytics, this doesn't send any content
 * unless it is defined, without using fallbacks. Each key should be checked
 * when necessary to prevent sending empty data.
 */
export const getLocalisation = (state: IApplicationState): ILocalisationProps => {
  const countryCode = getCountryCode(state);
  const currency = getCurrencyCode(state);
  const locale = getAppAnalyticsLocale(state);

  let hasAnyDirectory = false;

  if (countryCode) {
    hasAnyDirectory = gazetteer.getMarketByCountry(countryCode).hasAnyDirectory;
  }

  const countryName = countryCode ? Gazetteer.getCountryName(countryCode) : undefined;
  const liteVersion = countryCode ? !hasAnyDirectory : null;

  return {
    ...(!!countryCode && { countryCode }),
    ...(!!countryName && { countryName }),
    ...(!!currency && { currency }),
    ...(typeof liteVersion === 'boolean' && { liteVersion }),
    ...(!!locale && { locale }),
  };
};

export const createWeddingLevelIdentify =
  <G>(getState: () => IApplicationState) =>
  (traits: G) => {
    const { id: weddingId } = getState().weddings.profile;
    fetch(`/api/wedding-level/identify`, {
      body: JSON.stringify({
        weddingId,
        traits,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });
  };

export type SiteLocation =
  | 'search'
  | 'searchLandingPage'
  | 'home'
  | 'checklist'
  | 'supplierProfile'
  | 'venueConfirmationPopup'
  | 'onboarding'
  | 'shortlistedFavourites'
  | 'bookedSuppliersCarousel'
  | TShortlistTab
  | string;

export const getLocationName = (
  state: IApplicationState,
  overridePathname?: string,
): SiteLocation => {
  const {
    venueConfirmation: { showVenueConfirm, triggerLocation },
    shortlist: { addSupplierModalOpened },
    app: { query },
  } = state;
  const pathname = overridePathname ? overridePathname : state.app.pathname;
  const is = (path: string) => pathname.indexOf(path) === 0;

  switch (true) {
    case showVenueConfirm:
      return triggerLocation;
    case is('/home') || pathname === '/':
      return 'home';
    case is('/shortlist'):
      return addSupplierModalOpened ? 'shortlistedFavourites' : query.category || 'venues';
    case is('/onboarding'):
      return 'onboarding';
    case isSearchLandingPage(pathname):
      return 'searchLandingPage';
    case is('/search'):
      return 'search';
    case is('/checklist'):
      return 'checklist';
    default:
      return 'supplierProfile';
  }
};

export const labledLocations: ShortlistedLocation[] = [
  'venueRexCarousel',
  'recentlyViewedCarousel',
  'yourMatchesCarousel',
  'emptyShortlistCarousel',
];
