import { isObject, mapValues } from 'remeda';

export type Replace<T, S, R> = {
  [K in keyof T]: NonNullable<T[K]> extends S ? R : T[K];
};

export type ReplaceRecursive<T, S, R> = {
  [K in keyof T]: NonNullable<T[K]> extends S
    ? R
    : T[K] extends object
    ? Replace<T[K], S, R>
    : T[K];
};

export interface ITimestamp {
  toDate(): Date;
  toMillis(): number;
}

export type TTimestampedSerialized<T> = Replace<T, ITimestamp, number>;

/**
 * Converts a Firestore timestamp to a numeric timestamp (in milliseconds).
 */
export function serializeTimestamp<T extends ITimestamp>(value?: T): number | undefined;

/**
 * Returns the same value that was provided without any transformations.
 */
export function serializeTimestamp<T = any>(value?: T): T | undefined;

/**
 * Converts a Firestore timestamp to a numeric timestamp (in milliseconds).
 */
export function serializeTimestamp(value?: any) {
  return typeof value?.toMillis === 'function' ? value.toMillis() : value;
}

/**
 * Converts an object containing Firestore timestamps to a numeric timestamps (in milliseconds).
 */
export function serializeTimestamps<T extends object>(data: T) {
  return mapValues(data, serializeTimestamp) as Replace<T, ITimestamp, number>;
}

/**
 * Converts an array of objects containing Firestore timestamps to a numeric timestamps (in milliseconds).
 */
export function mapSerializeTimestamps<T = Record<string, unknown>>(
  data: T[],
): Replace<T, ITimestamp, number>[];

/**
 * Converts an object of objects containing Firestore timestamps to a numeric timestamps (in milliseconds).
 */
export function mapSerializeTimestamps<T = Record<string, unknown>>(
  data: T,
): Record<keyof T, Replace<T[keyof T], ITimestamp, number>>;

/**
 * Converts an array/object of objects containing Firestore timestamps to a numeric timestamps (in milliseconds).
 */
export function mapSerializeTimestamps(data: any) {
  return Array.isArray(data) ? data.map(serializeTimestamps) : mapValues(data, serializeTimestamps);
}

/**
 * Converts a Firestore timestamp to a numeric timestamp (in milliseconds) recursively.
 */
export function recursiveSerializeTimestamp<T = unknown>(
  value: T,
): ReplaceRecursive<T, ITimestamp, number> | number | T {
  if (typeof (value as unknown as ITimestamp)?.toMillis === 'function') {
    return (value as unknown as ITimestamp).toMillis(); // Cast value to ITimestamp before calling toMillis
  }

  if (isObject(value)) {
    return mapValues(value, recursiveSerializeTimestamp) as ReplaceRecursive<T, ITimestamp, number>;
  }

  return value;
}

/**
 * Converts an object containing Firestore timestamps to numeric timestamps (in milliseconds) recursively.
 */
export function recursiveSerializeTimestamps<T extends object>(
  data: T,
): ReplaceRecursive<T, ITimestamp, number> {
  return mapValues(data, recursiveSerializeTimestamp) as ReplaceRecursive<T, ITimestamp, number>;
}
