import { lastDayOfMonth, setDate, setHours } from 'date-fns';
import { getSeasonDurationInMonths } from '@bridebook/toolbox/src/datepicker/get-season-duration-in-months';
import { getSeasonMonth } from '@bridebook/toolbox/src/datepicker/get-season-month';
import { type Market } from '@bridebook/toolbox/src/gazetteer';
import { IDatePickerUI } from './types';

/**
 * @TODO: Check all usages.
 *
 *getSeasonMonth() * 3
 *	mapToExactDate() * 3
 *		makeOutput() * 2 🏁
 *		getWeddingDateTimestamp()
 *			useTableColumns() 🏁
 *		editEnquiryInfoEpic()
 *			editEnquiryInfo()
 *				EditDetailsModal 🏁
 *		firestoreOfferDatesToTimestamps()
 *			getLateAvailabilityFormStructure()
 *				convertFirestoreOfferToForm()
 *				getSpecialOfferByType()
 *					setInitialSpecialOfferEpic()
 *						getInitialForm()
 *							BenefitsSection 🏁
 *							OffersForm 🏁
 *			getOfferLatestDate()
 *				getExpiryDate()
 *					OfferTile 🏁
 *			getLateAvailabilityOfferExpiredDates()
 *				DashboardSuppliers 🏁
 *				DashboardVenues 🏁
 *				WidgetLateAvailability 🏁
 *			getOfferEarliestDate()
 *		WeddingDateField
 *			IframePage 🏁
 *		getAddedEnquiryPropertiesGeneric()
 *			trackSentWidgetEnquiry()
 *				submitEnquiry()
 *			trackClickedToSendEnquiry()
 *		weddingDetailsPropertiesGeneric()
 *			globalIdentify()
 *			onWrite(weddings/{weddingId})
 *			onWrite(/suppliers/{supplier}/weddings/{weddingId})
 *			onWrite(/suppliers/{supplierId}/files/{fileId})
 *			onWrite(/weddings/{weddingId}/files/{fileId})
 *			enquiriesServerSideTrack()
 *			sendEnquirySuccessfulAnalyticsEventEpic()
 *			uiAnalytics()
 *			collaboratingSupplierTrack()
 *			profileAnalytics()
 *		isFutureWedding()
 *			findFutureAndNotBookedAndWithLocationWeddings() * 2
 *		getDaysLeft() * 3
 *			WeddingCard 🏁
 *			ChecklistWeddingDate 🏁
 *		getActualLateAvailabilityDates()
 *			getLateAvailabilityExpiryDate()
 *				getLatestSpecialOfferDate()
 *			getLateAvailabilityDates()
 *				getMappedDocument()
 *		getActualOffers()
 *			processVenueOffers()
 *		formatWeddingDate()
 *			getBookedByCouples()
 *				getVenueBookings()
 *					/api/inbox/bookings
 *			getVenueBooking()
 *				/api/inbox/booking
 *		DashboardWeddingCountdown 🏁
 *		loadOnboardingData()
 *			Initial 🏁
 *		getPeriodName()
 *			PeriodGroupHeaderComponent 🏁
 *			getTaskPeriodOptions()
 *				AddTaskPopup 🏁
 *				DetailsTopBar 🏁
 *				TaskEdit 🏁
 *			getIdentifyGeneric()
 *		isGroupOutstanding()
 *			Details 🏁
 *			Task 🏁
 *		getTasksExactDate()
 *			initialiseChecklistEpic()
 *			getNextOrder()
 *				addTaskEpic()
 *				tasksPeriodChangeEpic()
 *			getPeriodList()
 *			getPeriodListFromNow()
 *		checklistStatePropertiesGeneric()
 *		getDateRange()
 *			getMonthsLeft()
 *				useEnquiriesGuard() 🏁
 *		convertLateDatesToTimestamps()
 *			SpecialOfferPopupInner 🏁
 *			getLatestDate()
 *				isActiveLateAvailability()
 *					TilesSpecialOffers
 *						SpecialComponent
 *							SpecialOffers 🏁
 *			specialOfferPropertiesGeneric()
 *				triggeredSpecialOfferPopUpAnalytics()
 *		getTargetingWeekday()
 *			getTargeting()
 *				createSearchQuery()
 *		getChecklistExactDate()
 *			initializedChecklistMapper()
 *	mapToDateRange()
 *		getWeddingDateRange()
 *			getDerivedParams()
 *				useSearch() 🏁
 *				fetchInitialProps() 🏁
 *			useIsWeddingDateWithinAYear()
 *				LateAvailabilityBannerLazy 🏁
 *				useQuickFilters() 🏁
 *  getSeasonInMonths() in WeddingEstimatePriceFilter
 */

/**
 * Function `mapToDateRange`
 * maps DatepickerDate to an an object with from date and to date.
 * Hour is set as 00 for from date.
 * Hour is set as 23 for to date.
 * This makes sure a date will be within date range
 * if year, month, day matches even if hour is higher than 00.
 */
export const mapToDateRange = (
  datePickerDate: IDatePickerUI | null,
  market: Market,
): { from: Date; to: Date } | null => {
  if (!datePickerDate) return null;

  const { year, month, season, monthUndecided, day } = datePickerDate;

  // when year, month and day are set
  if (year && month && day) {
    const exactDate = setDate(new Date(Number(year), Number(month), 1), Number(day));
    // date range will be the same day
    return {
      from: exactDate,
      // same day, last hour of the day
      to: setHours(exactDate, 23),
    };
  }
  // when year is set but month is not set
  if (!!year && !!monthUndecided) {
    return {
      // first day of January
      from: new Date(Number(year), 0 /* Jan */, 1, 0, 0),
      // last day of December, last hour of the day
      to: setHours(lastDayOfMonth(new Date(Number(year), 11 /* Dec */, 1)), 23),
    };
  }
  // when year and month are set
  if (!!year && !!month) {
    return {
      // first day of the month
      from: new Date(Number(year), Number(month), 1, 0, 0),
      // last day of the month, last hour of the day
      to: setHours(lastDayOfMonth(new Date(Number(year), Number(month), 1)), 23),
    };
  }
  // when year and season are set
  if (!!year && !!season) {
    const seasonMonth = getSeasonMonth(season, market);
    const seasonDuration = getSeasonDurationInMonths(season, market);

    return {
      // first day of the first month of a season
      from: new Date(Number(year), seasonMonth, 1, 0, 0),
      // last day of the last month of a season, last hour of the day
      to: setHours(lastDayOfMonth(new Date(Number(year), seasonMonth + seasonDuration - 1, 1)), 23),
    };
  }

  return null;
};
