import type { Slug } from '@bridebook/toolbox/src/types';
import IconSupplierCakeMaker from '@bridebook/ui/src/components/svg/icon-supplier-cake-maker';
import IconSupplierCaterer from '@bridebook/ui/src/components/svg/icon-supplier-caterer';
import IconSupplierDecorHire from '@bridebook/ui/src/components/svg/icon-supplier-decor-hire';
import IconSupplierDressmaker from '@bridebook/ui/src/components/svg/icon-supplier-dressmaker';
import IconSupplierEntertainment from '@bridebook/ui/src/components/svg/icon-supplier-entertainment';
import IconSupplierFlorist from '@bridebook/ui/src/components/svg/icon-supplier-florist';
import IconSupplierHairBeauty from '@bridebook/ui/src/components/svg/icon-supplier-hair-beauty';
import IconSupplierJeweller from '@bridebook/ui/src/components/svg/icon-supplier-jeweller';
import IconSupplierMarquee from '@bridebook/ui/src/components/svg/icon-supplier-marquee';
import IconSupplierMenswear from '@bridebook/ui/src/components/svg/icon-supplier-menswear';
import IconSupplierMusic from '@bridebook/ui/src/components/svg/icon-supplier-music';
import IconSupplierPhotographer from '@bridebook/ui/src/components/svg/icon-supplier-photographer';
import IconSupplierPlanner from '@bridebook/ui/src/components/svg/icon-supplier-planner';
import IconSupplierStationery from '@bridebook/ui/src/components/svg/icon-supplier-stationery';
import IconSupplierTransport from '@bridebook/ui/src/components/svg/icon-supplier-transport';
import IconSupplierVenue from '@bridebook/ui/src/components/svg/icon-supplier-venue';
import IconSupplierVideographer from '@bridebook/ui/src/components/svg/icon-supplier-videographer';
import { getI18n } from 'lib/i18n/getI18n';
import { SupplierCategory } from './types';

const getSupplierCategories: () => SupplierCategory[] = () => {
  const i18n = getI18n();
  const venues = i18n.t('supplierCategories:categories.venues');
  const photographers = i18n.t('supplierCategories:categories.photographers');
  const florists = i18n.t('supplierCategories:categories.florists');
  const video = i18n.t('supplierCategories:categories.video');
  const catering = i18n.t('supplierCategories:categories.catering');
  const dress = i18n.t('supplierCategories:categories.dress');
  const music = i18n.t('supplierCategories:categories.music');
  const entertainment = i18n.t('supplierCategories:categories.entertainment');
  const cakes = i18n.t('supplierCategories:categories.cakes');
  const stationery = i18n.t('supplierCategories:categories.stationery');
  const beauty = i18n.t('supplierCategories:categories.beauty');
  const menswear = i18n.t('supplierCategories:categories.menswear');
  const jewellery = i18n.t('supplierCategories:categories.jewellery');
  const transport = i18n.t('supplierCategories:categories.transport');
  const decoration = i18n.t('supplierCategories:categories.decoration');
  const planners = i18n.t('supplierCategories:categories.planners');
  const marquee = i18n.t('supplierCategories:categories.marquee');

  return [
    {
      name: venues,
      label: venues,
      value: 'venue',
      id: 0,
      icon: IconSupplierVenue,
      iconWidth: 20,
    },
    {
      name: photographers,
      label: photographers,
      value: 'photo',
      id: 1,
      icon: IconSupplierPhotographer,
      iconWidth: 20,
    },
    {
      name: florists,
      label: florists,
      value: 'florist',
      id: 2,
      icon: IconSupplierFlorist,
      iconWidth: 20,
    },
    {
      name: video,
      label: video,
      value: 'video',
      id: 3,
      icon: IconSupplierVideographer,
      iconWidth: 20,
    },
    {
      name: catering,
      label: catering,
      value: 'catering',
      id: 4,
      icon: IconSupplierCaterer,
      iconWidth: 20,
    },
    {
      name: dress,
      label: dress,
      value: 'dress',
      id: 5,
      icon: IconSupplierDressmaker,
      iconWidth: 20,
    },
    {
      name: music,
      label: music,
      value: 'music',
      id: 6,
      icon: IconSupplierMusic,
      iconWidth: 20,
    },
    {
      name: entertainment,
      label: entertainment,
      value: 'entertainment',
      id: 7,
      icon: IconSupplierEntertainment,
      iconWidth: 20,
    },
    {
      name: cakes,
      label: cakes,
      value: 'cakes',
      id: 8,
      icon: IconSupplierCakeMaker,
      iconWidth: 20,
    },
    {
      name: stationery,
      label: stationery,
      value: 'stationery',
      id: 9,
      icon: IconSupplierStationery,
      iconWidth: 20,
    },
    {
      name: beauty,
      label: beauty,
      value: 'beauty',
      id: 10,
      icon: IconSupplierHairBeauty,
      iconWidth: 20,
    },
    {
      name: menswear,
      label: menswear,
      value: 'menswear',
      id: 11,
      icon: IconSupplierMenswear,
      iconWidth: 20,
    },
    {
      name: jewellery,
      label: jewellery,
      value: 'jewellery',
      id: 12,
      icon: IconSupplierJeweller,
      iconWidth: 20,
    },
    {
      name: transport,
      label: transport,
      value: 'transport',
      id: 13,
      icon: IconSupplierTransport,
      iconWidth: 20,
    },
    {
      name: decoration,
      label: decoration,
      value: 'decoration',
      id: 14,
      icon: IconSupplierDecorHire,
      iconWidth: 20,
    },
    {
      name: planners,
      label: planners,
      value: 'planners',
      id: 15,
      icon: IconSupplierPlanner,
      iconWidth: 20,
    },
    {
      name: marquee,
      label: marquee,
      value: 'marquee',
      id: 16,
      icon: IconSupplierMarquee,
      iconWidth: 20,
    },
  ];
};

/**
 * For certain type of weddings, exclude specific supplier categories
 */
export const excludedSupplierCategories: Record<'groomOnly' | 'brideOnly', Slug[]> = {
  groomOnly: ['dress'],
  brideOnly: ['menswear'],
};

export default getSupplierCategories;
