/**
 * Function `formatUrl` will attempt to format `value` passed as argument into
 * a valid url or return null.
 *
 * @function formatUrl
 * @param {String|Null} value - Possible url,
 * if url doesn't start with `http` or `https` it will be prepended.
 * @returns {String|null} - Will return valid url, or if validation failed will return null.
 */

const urlRegex = /^https?:[/]{2}/i;

export const formatUrl = (url: string | null = null, queryParams?: string): string | null => {
  if (!url) return url;

  let resultUrl = url;

  if (urlRegex.test(resultUrl) !== true) {
    resultUrl = `http://${resultUrl}`;
  }

  if (queryParams) {
    resultUrl = `${resultUrl}?${queryParams}`;
  }

  return resultUrl;
};
