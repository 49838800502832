import type { IAddress } from '@bridebook/models/source/models/Weddings.types';

export function extractLocationName(location: IAddress | null): string {
  if (!location) {
    return '';
  }
  let weddingLocation = location.name || '';
  const isStreetName = Array.isArray(location.street) && location.street.includes(weddingLocation);
  if (isStreetName || location.postalCode === weddingLocation) {
    weddingLocation = `${weddingLocation} ${location.city || ''}`;
  }
  return weddingLocation.trim();
}
