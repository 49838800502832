import { toMillis } from '@bridebook/toolbox/src/time-utils/time-utils';
import { useQuery } from '@tanstack/react-query';
import { Experiment } from '../types';

const AB_TESTS_CACHE_TIME = toMillis(1, 'hour');

const QUERY_KEY = 'fetch-ab-tests';

/**
 * Custom React hook to fetch all A/B test configurations.
 *
 * This hook leverages `react-query` to asynchronously fetch the list of A/B tests
 * from the `/api/ab-tests` endpoint. It provides caching and stale time management
 * to optimize network requests and performance.
 *
 * @returns An object containing:
 * - `abTests`: `Experiment[] | undefined` - The array of fetched A/B test configurations, or `undefined` if not yet loaded.
 * - `abTestsLoaded`: `boolean` - A flag indicating whether the A/B tests have been fetched.
 * - `error`: `unknown` - Any error encountered during the fetch operation.
 *
 *
 * @remarks
 * - **Caching:** The hook caches the fetched data for 1 hour (`AB_TESTS_CACHE_TIME`) to prevent redundant network requests.
 * - **Stale Time:** Data is considered fresh for 1 hour; after that, it becomes stale and may be refetched on re-mount or query invalidation.
 * - **Error Handling:** If the fetch operation fails, it will not retry (`retry: 0`) and will return an empty array.
 * - **Performance Optimization:** By setting both `cacheTime` and `staleTime` to 1 hour, the hook minimizes network traffic while ensuring reasonably fresh data.
 */
export const useAbTestsFetch = () => {
  const {
    data: abTests,
    isFetched,
    error,
  } = useQuery<Experiment[]>(
    [QUERY_KEY],
    async () => {
      const response = await fetch('/api/ab-tests');
      if (!response.ok) {
        return [];
      }
      return response.json();
    },
    {
      cacheTime: AB_TESTS_CACHE_TIME,
      staleTime: AB_TESTS_CACHE_TIME,
      retry: 0, // If fetch fails, do not retry; use the existing data or empty array.
    },
  );

  return {
    abTests,
    abTestsLoaded: isFetched,
    error,
  };
};
