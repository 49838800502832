import { ISupplier_Type } from '@bridebook/models/source/models/Suppliers.types';
import gazetteer, { CountryCodes } from '../../gazetteer';

/**
 Calculates the 'from' value for a search based on the specified size and page number.
 @param {Object} options
 @param {number} options.size - The number of results per page.
 @param {number} options.page - The page number.
 @param {boolean} options.supplierRankId - Whether to use the default 'from' value.
 @returns {number} The 'from' value for the search.
 */
export const getFromValue = ({
  size,
  page,
  supplierRankId,
}: {
  size: number;
  page: number;
  supplierRankId: boolean;
}): number => {
  if (supplierRankId) return 0;
  return size * (page - 1);
};

/**
 * Returns radius for search
 * @param {string} countryCode
 * @param {string} type
 * @param {number} radiusIncrease
 * @returns {number}
 */
export const getRadius = (
  countryCode: CountryCodes,
  type: ISupplier_Type,
  radiusIncrease: number,
): number => {
  const baseRadius = getBaseRadius(countryCode, type);
  radiusIncrease = Number(radiusIncrease) || 0;
  return baseRadius + radiusIncrease;
};

/**
 * Returns base radius for search
 * @param {string} countryCode
 * @param {string} type
 * @returns {number}
 */
export const getBaseRadius = (countryCode: CountryCodes, type: ISupplier_Type): number => {
  const market = gazetteer.getMarketByCountry(countryCode);
  const { radius } = market.mappings.search;
  return radius?.[type] ?? radius['_'];
};

/**
 * Mapping of Elastic fields to relevant administrative areas ( returned by geocoding api )
 */
export enum ADMINISTRATIVE_AREAS {
  ADMIN_AREA_1 = 'adminAreaLevel1',
  ADMIN_AREA_2 = 'adminAreaLevel2',
}

export enum ADMINISTRATIVE_AREAS_LEGACY {
  ADMIN_AREA_1 = 'country',
  ADMIN_AREA_2 = 'county',
}

export const getCountyAdminAreaField = (
  countryCode: CountryCodes | undefined,
  useLegacy = false,
) => {
  const adminAreasToUse = useLegacy ? ADMINISTRATIVE_AREAS_LEGACY : ADMINISTRATIVE_AREAS;
  if (!countryCode) {
    return adminAreasToUse.ADMIN_AREA_2;
  }
  const market = gazetteer.getMarketByCountry(countryCode);
  return market.sortAdminAreas([adminAreasToUse.ADMIN_AREA_2, adminAreasToUse.ADMIN_AREA_1], 1)[0];
};
