import { useEffect, useState } from 'react';
import { basicUserIdCookie } from '../utils/cookies';

/**
 * Custom React hook to initialize a user ID based on the user's authentication state and page context.
 *
 * This hook determines whether to use the logged-in user's ID or generate a basic user ID for logged-out users.
 * It ensures that the user ID is set on the client side after the initial render to match server-side rendering constraints.
 *
 * @param {string} BASIC_USER_ID_COOKIE - The name of the cookie used to store the basic user ID for logged-out users.
 * @param {string} [loggedInUserId] - The user ID of the logged-in user, if available.
 * @param {boolean} [triggerForLoggedOutUsers] - Flag indicating whether to initialize the user ID for logged-out users.
 * @param {boolean} [isOnLoggedOutPage] - Flag indicating whether the current page is accessible to logged-out users only.
 *
 * @returns {string | undefined} The initialized user ID, either the logged-in user ID or a generated basic user ID.
 *
 * @example
 * // Usage within a component
 * const userId = useInitializeUserId(
 *   'b2b_basic_client_id',
 *   loggedInUserId,
 *   true, // triggerForLoggedOutUsers
 *   isOnLoggedOutPage
 * );
 *
 * // Now you can use userId for further logic
 * if (userId) {
 *   // Perform actions with the userId
 * }
 */
export const useInitializeUserId = (
  BASIC_USER_ID_COOKIE: string,
  loggedInUserId?: string,
  triggerForLoggedOutUsers?: boolean,
  isOnLoggedOutPage?: boolean,
): string | undefined => {
  const [userId, setUserId] = useState(loggedInUserId);

  // The first client-side render has to match the server, so we set the user ID
  // only in the second render using `useEffect`
  useEffect(() => {
    if (userId) return;

    const shouldSetLoggedInUserId = loggedInUserId && !isOnLoggedOutPage;
    const shouldSetLoggedOutUserId = isOnLoggedOutPage && triggerForLoggedOutUsers;

    if (shouldSetLoggedInUserId) {
      setUserId(loggedInUserId);
      return;
    }
    if (shouldSetLoggedOutUserId) {
      setUserId(basicUserIdCookie.set(BASIC_USER_ID_COOKIE));
      return;
    }
  }, [BASIC_USER_ID_COOKIE, isOnLoggedOutPage, loggedInUserId, triggerForLoggedOutUsers, userId]);

  return userId;
};
