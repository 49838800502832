import { values } from 'ramda';
import { createSelector } from 'reselect';
import { IApplicationState } from 'lib/types';
import { TEnquiryDates } from './types';

const _getEnquiryDates = (state: IApplicationState) => state.enquiries.enquiryDates;
const _getEnquiries = (state: IApplicationState) => state.enquiries;

export const getEnquiryDates = createSelector([_getEnquiryDates], (enquiryDates) =>
  values(enquiryDates).reduce((acc, val) => {
    if (val?.supplierId && val?.enquired) {
      acc[val.supplierId] = val.enquired;
    }
    return acc;
  }, {} as TEnquiryDates),
);

export const getShowEnquiryForm = createSelector(
  _getEnquiries,
  ({ showEnquiryForm }) => showEnquiryForm,
);

export const getEnquiryFormBusy = createSelector(
  _getEnquiries,
  (enquiries) => enquiries.enquiryFormBusy,
);

export const getEnquiryConfirmationSupplier = createSelector(
  _getEnquiries,
  (enquiries) => enquiries.enquiryConfirmationSupplier,
);

export const getEnquiryFormError = createSelector(
  [_getEnquiries],
  (enquiries) => enquiries.enquiryForm.error,
);

export const getEnquiryFormFields = createSelector(
  [_getEnquiries],
  (enquiries) => enquiries.enquiryForm.fields,
);

export const getEnquiryConfirmationSupplierType = createSelector(
  getEnquiryConfirmationSupplier,
  (supplier) => supplier?.type[0],
);

export const getEnquiryConfirmationSupplierId = createSelector(
  getEnquiryConfirmationSupplier,
  (supplier) => supplier?.id,
);

export const getIsEnquiryConfirmationSupplierVenueType = createSelector(
  getEnquiryConfirmationSupplierType,
  (type) => type === 'venue',
);

export const getEnquiryConfirmationSupplierName = createSelector(
  getEnquiryConfirmationSupplier,
  (supplier) => supplier?.name || '',
);

export const getEnquiryCount = createSelector(
  getEnquiryDates,
  (enquiries) => enquiries.enquiryCount,
);

export const selectEnquiryFormContext = createSelector(
  _getEnquiries,
  (enquiries) => enquiries.enquiryForm.context,
);

export const getEnquiryFormEditState = createSelector(
  _getEnquiries,
  (enquiries) => enquiries.enquiryFormEditState,
);

export const getEnquiryFormDatepickerState = createSelector(
  _getEnquiries,
  (enquiries) => enquiries.enquiryFormDatepickerState,
);

export const getEnquirySupplier = createSelector(
  _getEnquiries,
  (enquiries) => enquiries.enquirySupplier,
);

export const getEnquiryConfirmationScreenShown = createSelector(
  _getEnquiries,
  (enquiries) => enquiries.enquiryConfirmationScreenShown,
);

export const getEnquirySupplierContacted = createSelector(
  _getEnquiries,
  (enquiries) => enquiries.supplierContacted,
);

export const getSupplierTriedToContact = createSelector(
  _getEnquiries,
  (enquiries) => enquiries.supplierTriedToContact,
);

export const getSendEnquiryFailed = createSelector(
  _getEnquiries,
  (enquiries) => enquiries.sendEnquiryFailed,
);

export const getEnquiryFormIntents = createSelector(_getEnquiries, (enquiries) => enquiries.intent);

export const getHasEnquiryAnyIntentSelected = createSelector(getEnquiryFormIntents, (intent) =>
  Object.values(intent).some(Boolean),
);

export const getOnPageEnquiry = createSelector(
  _getEnquiries,
  (enquiries) => enquiries.onPageEnquiry,
);

export const getEnquiryInfoProps = createSelector(
  _getEnquiries,
  (enquiries) => enquiries.infoProps,
);

export const LIVE_21330_getEnquiryHasIntentFastResponseABTest = createSelector(
  _getEnquiries,
  (enquiries) => enquiries.LIVE_21330_enquiryHasIntentFastResponseABTest,
);
