import { type Market } from '@bridebook/toolbox/src/gazetteer';
import { formatDate } from '../elastic-sync/utils/dateUtils';
import { mapToDateRange } from './map-to-date-range';
import { IDatePickerUI } from './types';

/**
 * Function `mapToExactDate`
 * map DatepickerDate to a full string to be used across the website
 *
 * @function mapToExactDate
 * @param {Object} - IDatePickerUI
 * @returns {String || null}
 */
const mapToExactDate = (datePickerDate: IDatePickerUI | null, market: Market): string => {
  if (!datePickerDate) return '';

  const dateRange = mapToDateRange(datePickerDate, market);

  return dateRange ? formatDate(dateRange.from) : '';
};

export default mapToExactDate;
