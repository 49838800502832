import { useMemo } from 'react';
import { useActivateAbTest } from '@bridebook/toolbox/src/ab-testing/lib/hooks/use-activate-ab-tests';
import { useInitializeUserId } from '@bridebook/toolbox/src/ab-testing/lib/hooks/use-intitialize-user-id';
import { loggedOutOnlyPages } from 'lib/url-helper';
import { getCurrentUserId } from 'lib/users/selectors';
import { useSelector } from 'lib/utils';
import { usePathWithoutMarket } from 'lib/utils/url';

export const BASIC_USER_ID_COOKIE = 'basic_client_id';

/**
 * Activates an AB Test
 *
 * @param testId - id of the test from firestore
 * @param triggerCondition (optional) - test will run only if this is undefined or true
 * @param triggerForLoggedOutUsers (optional) - flag to trigger the test for logged out users (eg /signup page)
 */
export const useABTestIntegration = ({
  testId,
  triggerCondition = true,
  triggerForLoggedOutUsers,
}: {
  testId: string;
  triggerCondition?: boolean;
  triggerForLoggedOutUsers?: boolean;
}) => {
  const loggedInUserId = useSelector(getCurrentUserId);
  const path = usePathWithoutMarket();

  const isOnLoggedOutPage = useMemo(
    () => loggedOutOnlyPages.some((page) => path.includes(page)),
    [path],
  );

  const userId = useInitializeUserId(
    BASIC_USER_ID_COOKIE,
    loggedInUserId,
    triggerForLoggedOutUsers,
    isOnLoggedOutPage,
  );

  const assignedVariant = useActivateAbTest({
    triggerCondition,
    userId,
    testId,
  });

  return {
    variant: assignedVariant,
  };
};
