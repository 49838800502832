import { compose, filter, last, values } from 'ramda';
import type { Slug } from '@bridebook/toolbox/src/types';
import { sortByUpdatedAt } from 'lib/utils';

/* Get last item in shortlisted sorted by createdAt and that is also of a slug venue and is booked */
const getLastBookedSupplier = <T extends { booked: boolean; type: Slug; updatedAt?: number }>(
  list: Record<string, T>,
  type: Slug,
): T | undefined => {
  const isBookedSupplier = (item: T) => item.booked && item.type === type;

  //FIXME: old ramda types problem
  //@ts-ignore FIXME
  return compose(last, sortByUpdatedAt, filter(isBookedSupplier), values)(list);
};

export default getLastBookedSupplier;
