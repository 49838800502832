import type { UserInfo } from 'firebase/auth';
import { createSelector } from 'reselect';
import getAuthStatus from 'lib/auth/utils/get-auth-status';
import { IApplicationState } from 'lib/types';
import { imgixBaseURL } from 'lib/utils';
import { assertExists } from 'lib/utils/assertExists';

const _getCurrentUser = (state: IApplicationState) => state.users?.user;
const _getUsers = (state: IApplicationState) => state.users;

export const selectCurrentUser = createSelector([_getCurrentUser], (user) => user);
export const selectLoadedUser = createSelector([_getCurrentUser], (user) => {
  assertExists(user);
  return user;
});
export const selectUserLocale = createSelector([_getCurrentUser], (user) => user?.l10n?.locale);
export const selectIsUserLoggedIn = createSelector([_getCurrentUser], (user) =>
  getAuthStatus({ user }),
);

export const selectCurrentUserContactPhone = createSelector(
  [selectCurrentUser],
  (user) => user?.contacts?.phone,
);

export const selectCurrentUserContactEmail = createSelector(
  [selectCurrentUser],
  (user) => user?.contacts?.email ?? '',
);

export const getCurrentUserName = createSelector([_getCurrentUser], (user) => user?.name);
export const getCurrentUserId = createSelector([_getCurrentUser], (user) => user?.id);
export const getCurrentUserDeleteAt = createSelector([_getCurrentUser], (user) => user?.deleteAt);

export const getProviders = createSelector([_getUsers], (users) => users.providers);

export const getSocialPhoto = createSelector([getProviders], (providers) => {
  const provider: UserInfo | undefined = Object.values(providers || {}).find(
    (x: UserInfo) => !!x?.photoURL,
  );

  return provider?.photoURL;
});

export const getProfilePhoto = createSelector([selectCurrentUser], (user) => {
  const userPhoto = user?.photo?.path;

  return userPhoto ? `${imgixBaseURL}/${userPhoto}` : '';
});

export const getProfilePhotoUploadProgress = createSelector(
  [_getUsers],
  (users) => users.profilePhotoUploadProgress,
);
