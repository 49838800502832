import { isEmpty } from 'ramda';
import { createSelector } from 'reselect';
import { PremiumTiers } from '@bridebook/toolbox';
import { getPricingCategoryByIndicator } from 'app-shared/lib/supplier/utils';
import { RequiredTiersManager } from 'lib/premium/required-tiers-manager';
import getSupplierCategories, {
  excludedSupplierCategories,
} from 'lib/supplier/supplier-categories';
import {
  CastedDiningCapacity,
  IApplicationState,
  ISupplierState,
  SupplierCategory,
} from 'lib/types';
import { assertState } from 'lib/utils/assertState';
import { isBrideOnlyWedding, isGroomOnlyWedding } from 'lib/weddings/selectors';

const getPathname = (state: IApplicationState) => state.app.pathname;
const getQuery = (state: IApplicationState) => state.app.query;
const getSupplierState = (state: IApplicationState) => state.supplier;
const getSupplierRelations = (state: IApplicationState) => state.supplier.relations;

export const getIsSupplierVenue = createSelector(
  [getPathname, getQuery],
  (pathname, query) => pathname.startsWith('/wedding-venues/') && query && query.slug === 'venues',
);

// Leftovers form an old AB test, but we wanted to keep the banner
export const getIsWhyBridebookBannerVisible = () => false;

export const getCurrentSupplier = createSelector([getSupplierState], (supplier) => {
  assertState(supplier.supplier, 'loaded', 'supplier');
  return supplier.supplier.data;
});

export const getCurrentSupplierHasBadDebt = createSelector(
  [getSupplierState],
  (supplier) => supplier.hasBadDebt,
);

export const getCurrentSupplierType = createSelector(
  [getCurrentSupplier],
  (supplier) => supplier.type?.[0],
);

export const selectRecommendedPhotographer = createSelector([getSupplierRelations], (relations) =>
  relations?.find((relation) => relation.type === 'photo'),
);

export const getCurrentSupplierId = createSelector(
  [getCurrentSupplier],
  (supplier) => supplier?.id,
);

export const getCurrentSupplierCalendarLink = createSelector(
  [getCurrentSupplier],
  ({ contacts: { calendarUrl } }) => calendarUrl,
);

export const getSpecialOffers = createSelector([getSupplierState], (supplier) => supplier.offers);

export const getPremium = createSelector([getSupplierState], (supplier) => supplier.premium);

export const getQuestions = createSelector([getSupplierState], (supplier) => supplier.questions);

export const getRecommendations = createSelector(
  [getSupplierState],
  (supplier) => supplier.recommendations,
);

export const getBadges = createSelector([getSupplierState], (supplier) => supplier.badges);

export const getSupplierPhotos = createSelector(
  [getSupplierState],
  (supplier) => supplier.photos.photos,
);

export const getFairs = createSelector([getSupplierState], (supplier) => supplier.fairs);

export const getCurrentSupplierPhotosCount = createSelector(
  [getCurrentSupplier],
  (supplier) => supplier.counters?.photos || 0,
);

export const getVenuePackages = createSelector([getSupplierState], (supplier) => supplier.packages);

export const getSupplierPackages = createSelector(
  [getSupplierState],
  (supplier) => supplier.supplierPackages,
);

export const getCurrentSupplierFeedbackCount = createSelector(
  [getCurrentSupplier],
  (supplier) => Number(supplier.counters?.reviews) + Number(supplier.counters?.testimonials) || 0,
);

export const getSupplierVideos = createSelector([getSupplierState], (supplier) =>
  supplier.videos ? supplier.videos.filter((item) => item.type === 'video') : [],
);

export const getCurrentSupplierVideosCount = createSelector(
  [getCurrentSupplier],
  (supplier) => supplier.counters?.videos || 0,
);

export const getSupplierVideoTours = createSelector(
  [getSupplierState, getPremium],
  (supplier, premium) => {
    if ((premium?.tier || PremiumTiers.Tier_0) >= RequiredTiersManager.videoTours.page) {
      return supplier.videos ? supplier.videos.filter((item) => item.type === 'videoTour') : [];
    }
    return [];
  },
);

export const getCurrentSupplierVideoToursCount = createSelector(
  [getCurrentSupplier, getPremium],
  (supplier, premium) => {
    if ((premium?.tier || PremiumTiers.Tier_0) >= RequiredTiersManager.videoTours.page) {
      return supplier.counters?.videoTours || 0;
    }
    return 0;
  },
);

export const getSupplierBrochures = createSelector(
  [getSupplierState],
  (supplier) => supplier.brochures,
);

export const getIsCurrentSupplierVenueType = createSelector(
  getCurrentSupplierType,
  (type) => type === 'venue',
);

export const getIsQuickResponderAvailableForCurrentSupplier = createSelector(
  [getIsCurrentSupplierVenueType, getPremium],
  (isVenue, premium) => !isVenue && premium?.active,
);

export const getIsCurrentSupplierPhotoType = createSelector(
  getCurrentSupplierType,
  (type) => type === 'photo',
);

export const getHasCurrentSupplierBrochures = createSelector(
  [getSupplierState, getIsCurrentSupplierVenueType],
  (supplier, isVenue) => isVenue && !isEmpty(supplier.brochures),
);

/**
 * Venue supplier details
 */
const getCurrentSupplierVenueData = createSelector(
  getCurrentSupplier,
  (state) => state?.typeDetails?.venue,
);

export const getCurrentSupplierVenueDetails = createSelector(
  getCurrentSupplierVenueData,
  (venue) => venue?.details || [],
);

export const getCurrentSupplierCeremonyReception = createSelector(
  getCurrentSupplierVenueData,
  (venue) => venue?.ceremonyReception || [],
);

export const getVenueSupplierDiningCapacity = createSelector(
  getCurrentSupplierVenueData,
  (venue) => {
    const diningCapacity = venue?.capacity?.dining as CastedDiningCapacity;
    const normalizedDiningCapacity = diningCapacity ? diningCapacity : 0;
    return typeof normalizedDiningCapacity === 'string'
      ? parseInt(normalizedDiningCapacity, 10)
      : diningCapacity;
  },
);

export const getCurrentSupplierAccommodationRooms = createSelector(
  getCurrentSupplierVenueData,
  (venue) => venue?.accommodation?.rooms || 0,
);

/**
 * Returns list of supplier categories based on the type of wedding partners
 */
export const getCurrentSupplierCategoriesForWeddingType = createSelector(
  [
    // Use anonymous functions to avoid circular-dependency build error
    // @see: https://github.com/reduxjs/reselect/issues/169
    (state: IApplicationState) => isBrideOnlyWedding(state),
    (state: IApplicationState) => isGroomOnlyWedding(state),
  ],
  (isBrideOnly, isGroomOnly): SupplierCategory[] => {
    const excluded = isGroomOnly
      ? excludedSupplierCategories.groomOnly
      : isBrideOnly
      ? excludedSupplierCategories.brideOnly
      : [];

    if (!excluded.length) return getSupplierCategories();

    return getSupplierCategories().filter((c) => !excluded.includes(c.value));
  },
);

export const getCurrentSupplierPricingCategory = createSelector(getCurrentSupplier, (supplier) =>
  getPricingCategoryByIndicator(supplier?.pricing?.indicator),
);

export const getGalleryInitIndex = createSelector(
  getSupplierState,
  (supplier) => supplier.galleryInitIndex,
);

export const getSupplierShowGallery = createSelector(
  getSupplierState,
  (supplier) => supplier.showGallery,
);

export const getShowReviewsPage = createSelector(
  getSupplierState,
  (supplier) => supplier.showReviewsPage,
);

export const getCurrentSupplierWeddingsServedRange = createSelector(
  getCurrentSupplier,
  (supplier) => supplier?.company?.weddingsHosted,
);

export const getCurrentSupplierBasePrice = createSelector(
  getCurrentSupplier,
  (supplier) => supplier?.pricing?.base,
);

export const getAreReviewsMoved = createSelector(
  [(state) => state.app.device.isMobile, (state) => Boolean(state.users.user)],
  (isMobile, isLoggedIn) => isMobile && isLoggedIn,
);

export const getSupplierConnectedApps = createSelector(
  getSupplierState,
  (supplier) => supplier?.appsConnected,
);

export const getSupplierCountry = createSelector(
  getCurrentSupplier,
  (supplier) => supplier.l10n?.country,
);

export const getSupplierLoaded = createSelector(
  getSupplierState,
  (supplier: ISupplierState) => supplier.supplier.status === 'loaded',
);

export const getSupplierDataLoaded = createSelector(
  getSupplierState,
  (supplier: ISupplierState) => supplier.supplierDataStatus === 'loaded',
);

export const getLoadingSupplier = createSelector(
  getSupplierState,
  (supplier: ISupplierState) => supplier.supplier,
);

export const getCurrentLoadingSupplierId = createSelector(
  [getLoadingSupplier],
  (loadingSupplier) => loadingSupplier.data?.id,
);

export const getCurrentLoadingSupplierType = createSelector(
  [getLoadingSupplier],
  (loadingSupplier) => loadingSupplier.data?.type?.[0],
);

export const getCurrentLoadingSupplierIsVenue = createSelector(
  getCurrentLoadingSupplierType,
  (type) => (type ? type === 'venue' : undefined),
);

export const getSupplierInstagramUrl = createSelector(
  getCurrentSupplier,
  (supplier) => supplier?.social?.instagram,
);

export const getCurrentSupplierCurrency = createSelector(
  getCurrentSupplier,
  (supplier) => supplier.l10n?.currency,
);

export const getCurrentSupplierReviews = createSelector(
  getSupplierState,
  (supplier) => supplier.reviews,
);

export const getCurrentSupplierTrustedPartnerSinceYear = createSelector(
  getSupplierState,
  (supplier) =>
    supplier.trustedPartnerSince && new Date(supplier.trustedPartnerSince).getFullYear(),
);

export const getCurrentSupplierAccommodationPeople = createSelector(
  getCurrentSupplierVenueData,
  (venue) => venue?.accommodation?.people || 0,
);
