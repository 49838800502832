import { equals, pathOr } from 'ramda';
import { createSelector } from 'reselect';
import { IWedding } from '@bridebook/models/source/models/Weddings.types';
import gazetteer, { CountryCodes, Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import {
  ADMINISTRATIVE_AREAS_LEGACY,
  getCountyAdminAreaField,
} from '@bridebook/toolbox/src/search-suppliers/elastic-queries/utils';
import type { Slug } from '@bridebook/toolbox/src/types';
import { extractLocationName } from 'app-shared/lib/search/utils/utils';
import { getAreaContext } from 'app-shared/lib/supplier/utils/get-location-context';
import { getCountryCodeWithFallback, getLocalisation } from 'lib/i18n/selectors';
import { getWeddingVenue } from 'lib/shortlist/selectors';
import { IApplicationState } from 'lib/types';
import { toTitleCase } from 'lib/utils';
import { createDeepEqualSelector } from 'lib/utils/selectors';
import { PartnerRoles } from 'lib/weddings/types';

export const getBookings = (state: IApplicationState): IWedding['bookings'] =>
  state.weddings.profile.bookings;
const profileLoaded = (state: IApplicationState): boolean => state.weddings.loaded;
export const getIsProfileLoading = (state: IApplicationState): boolean => state.weddings.loading;
const date = (state: IApplicationState) => state.weddings.profile.date;
const partners = (state: IApplicationState) => state.weddings.profile.partners;
export const getWeddingLocation = (state: IApplicationState) => state.weddings.profile.location;
export const getWeddingId = (state: IApplicationState) => state.weddings.profile.id;
export const getWeddingCollaborators = (state: IApplicationState) => state.weddings.collaborators;
export const getWeddingUsers = (state: IApplicationState) => state.weddings.profile.users;
export const getInboxUnreadCount = (state: IApplicationState) => state.weddings.inboxUnread;
export const getWeddingLocationName = (state: IApplicationState) => getWeddingLocation(state)?.name;
const roles = (state: IApplicationState) => state.weddings.profile.roles;
export const getProfile = (state: IApplicationState) => state.weddings.profile;
export const getProfileNotesCount = (state: IApplicationState) =>
  state.weddings.profile?.notes?.count;

/* ############################################################################
 *  MEMOIZED SELECTORS
 * ######################################################################### */

/** used in onboarding where we need all 3 states of this property:
 * unspecified (undefined);
 * booked (true);
 * not booked (false);
 */
export const getVenueBookedUndefined = createSelector(
  [getBookings],
  (bookings: IWedding['bookings']): boolean | undefined => pathOr(undefined, ['venue'], bookings),
);

export const getVenueBooked = createSelector(
  [getBookings],
  (bookings: IWedding['bookings']): boolean => pathOr(false, ['venue'], bookings),
);

export const getProfileLoaded = createSelector(
  [profileLoaded],
  (profileLoaded: boolean): boolean => profileLoaded,
);

export const getPartners = createDeepEqualSelector([partners], (partners) => partners);

export const getIsMissingPartners = createSelector([partners], (partners) => partners.includes(''));

export const getWeddingDate = createDeepEqualSelector(
  date,
  // deep compare new date object with cache
  (date) => date,
);

/**
 * Returns formatted location of a booked venue if exists or from the wedding profile.
 * TODO: [i18n][bb-global] Check is `location?.adminArea?.[1]` is correct for US. Likely not.
 */
export const getVenueOrWeddingLocation = createSelector(
  [(state) => getWeddingVenue(state), getWeddingLocation, getCountryCodeWithFallback],
  (weddingVenue, location, countryCode): string | null => {
    /**
     * TODO: [i18n] For US, we are not getting the most specific area, but just the state. 🤔
     */
    if (location?.country === CountryCodes.US && location?.adminArea?.[1] != null) {
      return location?.adminArea?.[1];
    }

    if (weddingVenue?.town) {
      return weddingVenue.town;
    }

    if (location?.name) {
      return toTitleCase(extractLocationName(location)).replace(/-/g, ' ');
    }

    if (location?.adminArea?.[0] && location?.adminArea?.[1]) {
      // if no town or location name, return value that is used for county search. Germany uses country field for that.
      const countyField = getCountyAdminAreaField(countryCode, true);
      if (countyField === ADMINISTRATIVE_AREAS_LEGACY.ADMIN_AREA_1) {
        return location.adminArea[1];
      } else if (countyField === ADMINISTRATIVE_AREAS_LEGACY.ADMIN_AREA_2) {
        return location.adminArea[0];
      }
    }
    return null;
  },
);

/**
 * Returns formatted location of a booked venue (with it's name) if exists or from the wedding
 * profile.
 */
export const getVenueOrWeddingLocationUIString = createSelector(
  [(state) => getWeddingVenue(state), getWeddingLocation],
  (weddingVenue, location): string | null => {
    if (weddingVenue) {
      const { name = '', town } = weddingVenue;
      return town ? `${name}, ${town}` : name;
    }
    if (location?.name) {
      return toTitleCase(extractLocationName(location)).replace(/-/g, ' ');
    }
    return null;
  },
);

/**
 * Use booked venue or wedding location name first, then fallback to US whitelisted state, and
 * lastly fallback to wedding country name
 */
export const getLocationName = createSelector(
  [getVenueOrWeddingLocation, getLocalisation],
  (venueOrWeddingLocation, l10n): string =>
    venueOrWeddingLocation
      ? venueOrWeddingLocation
      : l10n?.country
      ? Gazetteer.getCountryName(l10n.country) || ''
      : '',
);

/**
 * Try to get most appropriate location context (country in this case)
 */
export const getLocationContext = createSelector(
  [getWeddingLocation, getCountryCodeWithFallback],
  (location, countryCode): string[] => getAreaContext(location, countryCode),
);

export const getDefaultSearchParams = createSelector(
  [getLocationName, getLocationContext],
  (area, areaContext) => ({
    slug: 'venue' as Slug,
    area,
    areaContext,
    filters: {},
  }),
);

export const isBrideOnlyWedding = createSelector([roles], (roles = []) =>
  equals(roles, [PartnerRoles.bride, PartnerRoles.bride]),
);

export const isGroomOnlyWedding = createSelector([roles], (roles = []) =>
  equals(roles, [PartnerRoles.groom, PartnerRoles.groom]),
);

export const isSameSexWedding = createSelector(
  [isBrideOnlyWedding, isGroomOnlyWedding],
  (bridesOnly, groomsOnly) => bridesOnly || groomsOnly,
);

export const getChecklistInitializationDate = createSelector(getProfile, (profile) =>
  profile ? new Date(profile.tasks.initializedAt || 0).toISOString() : undefined,
);

export const getWeddingProfileId = createSelector(getProfile, ({ id }) => id);

export const getWeddingBudget = createSelector(getProfile, (profile) => profile.budget);
export const getEstimatedGuests = createSelector(getProfile, (profile) => profile.guests.estimate);
export const getAddedGuests = createSelector(getProfile, (profile) => profile.guests.count);
export const getWeddingCurrency = createSelector(getProfile, (profile) => profile.l10n?.currency);
export const selectHasCollaborators = createSelector(
  getWeddingUsers,
  (weddingUsers) => weddingUsers.length > 1,
);

/**
 * Determines if wedding location is generic (United Kingdom or Deutschland for example) or not.
 */
export const getHasGenericWeddingLocation = createSelector(getWeddingLocation, (location) => {
  const countries = gazetteer
    .getMarkets()
    .map((market) => market.getCountryName().toLocaleLowerCase());

  return !!(location?.name && countries.includes(location.name.trim().toLocaleLowerCase()));
});

export const getWeddingMicrosite = createSelector(getProfile, (profile) => profile.microsite);
